<template>
  <moe-page title="优惠券入口管理">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div>优惠券入口管理</div>
          <!-- <div>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd()">新增广告位</el-button>
          </div> -->
        </div>
      </template>

      <moe-table ref="couponManageRef" url="/shop/home/list" :params="params" :numberShow="false">
        <el-table-column label="ID" min-width="100" prop="id" />
        <el-table-column label="所属页面" min-width="100" prop="page">
          <template slot-scope="{ row }">
            首页
          </template>
        </el-table-column>
        <el-table-column label="广告位名称" min-width="100" prop="name">
          <template slot-scope="{ row }">
            {{ row.name }}
          </template>
        </el-table-column>
        <el-table-column label="组件类型" min-width="100" prop="componentsType">
          <template slot-scope="{ row }">
            营销组件
          </template>
        </el-table-column>

        <el-table-column label="组件名称" min-width="100" prop="componentsName">
          <template slot-scope="{ row }">
            优惠券
          </template>
        </el-table-column>
        <el-table-column label="组件样式" min-width="100" prop="componentsStyle">
          <template slot-scope="{ row }">
            横向滑动
          </template>
        </el-table-column>
        <el-table-column label="广告数量" min-width="100" prop="count" />
        <el-table-column label="状态" width="90">
          <template slot-scope="{ row }">
            <div v-if="row.status" @click="handleShelf(row, false, '禁用')">
              <moe-tag type="success"><i class="el-icon-unlock" />启用</moe-tag>
            </div>
            <div v-else @click="handleShelf(row, true, '启用')">
              <moe-tag type="danger"><i class="el-icon-lock" />禁用</moe-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/contentManage/couponManage/add`, { id: row.id })">编辑</el-button>
          </template>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ContentManageCouponManageList',
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10
      },
    }
  },
  methods: {
    /** 新增广告位 */
    handleAdd() {
      if (this.$refs['couponManageRef'].tableData && this.$refs['couponManageRef'].tableData.length >= 1) {
        this.$moe_msg.warning('请联系管理员添加');
      } else {
        this.$moe_coordinator.navigateTo('/contentManage/couponManage/add');
      }
    },
    /** 状态修改 */
    handleShelf({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要“${text}”当前选项吗？`, () => {
        this.$moe_api.CONTENT.updateHomeStatus({ id, status }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['couponManageRef'].loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      })
    },
  },
}
</script>

<style lang="scss">

</style>